<template>
  <div class="relative bg-gray-50">
    <main class="lg:relative">
      <div class="mx-auto max-w-7xl w-full pt-16 pb-20 text-center lg:py-48 lg:text-left">
        <div class="px-4 lg:w-1/2 sm:px-8 xl:pr-16">
          <h1 class="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl lg:text-5xl xl:text-6xl">
            <span class="block xl:inline">Accountancy to enrich your</span>
            {{ ' ' }}
            <span class="block text-blue-800 xl:inline">business</span>
          </h1>
          <p class="mt-3 max-w-md mx-auto text-lg text-gray-500 sm:text-xl md:mt-5 md:max-w-3xl">Anim aute id magna aliqua ad ad non deserunt sunt. Qui irure qui lorem cupidatat commodo. Elit sunt amet fugiat veniam occaecat fugiat aliqua.</p>
          <div class="mt-10 sm:flex sm:justify-center lg:justify-start">
            <div class="rounded-md shadow">
              <a href="#" class="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-blue-800 hover:bg-blue-600 md:py-4 md:text-lg md:px-10"> Contact Us </a>
            </div>
            <!--<div class="mt-3 rounded-md shadow sm:mt-0 sm:ml-3">
              <a href="#" class="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-indigo-600 bg-white hover:bg-gray-50 md:py-4 md:text-lg md:px-10"> Live demo </a>
            </div>-->
          </div>
        </div>
      </div>
      <div class="relative w-full h-64 sm:h-72 md:h-96 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 lg:h-full">
        <img class="absolute inset-0 w-full h-full object-cover" src="https://images.unsplash.com/photo-1605025473092-2c9f56710dfc?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870" alt="" />
      </div>
    </main>
  </div>
  <blog-section />
  <footer-section />
</template>

<script>
import {
  BookmarkAltIcon,
  ChartBarIcon,
  CursorClickIcon,
  PhoneIcon,
  PlayIcon,
  ShieldCheckIcon,
  SupportIcon,
  ViewGridIcon,
} from '@heroicons/vue/outline'
import FooterSection from '../components/FooterSection.vue'
import BlogSection from '../components/BlogSection.vue'

const features = [
  {
    name: 'Acountancy',
    href: '#',
    description: 'Numbers and stuff.',
    icon: ChartBarIcon,
  },
  {
    name: 'Adding',
    href: '#',
    description: 'Add numbers to stuff.',
    icon: CursorClickIcon,
  },
  { name: 'More Adding', href: '#', 
    description: "Do things.", 
    icon: ShieldCheckIcon },
  {
    name: 'Other things',
    href: '#',
    description: "Connect and do things.",
    icon: ViewGridIcon,
  },
]
const callsToAction = [
  { name: 'Add with us', href: '#', icon: PlayIcon },
  { name: 'Contact Us', href: '#', icon: PhoneIcon },
]
const resources = [
  {
    name: 'Help Center',
    description: 'Get all of your adding questions answered.',
    href: '#',
    icon: SupportIcon,
  },
  {
    name: 'Guides',
    description: 'Learn how to do tax stuff.',
    href: '#',
    icon: BookmarkAltIcon,
  },
]
const recentPosts = [
  { id: 1, name: 'We can help add', href: '#' },
  { id: 2, name: 'Do adding the right way', href: '#' },
  { id: 3, name: 'Some tax nonsense', href: '#' },
]

export default {
  components: {
    FooterSection,
    BlogSection
  },
  setup() {
    return {
      features,
      callsToAction,
      resources,
      recentPosts,
    }
  },
}
</script>

<style>

</style>